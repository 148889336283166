// types
import { IVehicle } from '@makemydeal/dr-platform-types';
import { IDxgPowertrain, IDxgPriceCostDetails, IDxgVehicle, IDxgVehicleCodes } from '@makemydeal/dr-shared-types';

// utils
import { DealExchangeValidator } from '@makemydeal/dr-shared-ui-utils';

export const mapToDxgVehicle = (vehicle: IVehicle): IDxgVehicle => {
    const condition = DealExchangeValidator.condition(vehicle.condition);

    return {
        inventoryType: condition,
        exteriorColor: vehicle.exteriorColor,
        interiorColor: vehicle.interiorColor,
        vehicleImage: vehicle.imageUrl,
        vin: vehicle.vin,
        year: vehicle.year?.toString(),
        make: vehicle.make,
        model: vehicle.model,
        trim: vehicle.trim,
        bodyStyle: vehicle.bodyStyle,
        odometerMileage: vehicle.mileage,
        stockNumber: vehicle.stockNumber,
        vehicleUse: 'Personal',
        priceCostDetails: mapToDxgPriceCostDetails(vehicle),
        powertrain: mapToDxgPowertrain(vehicle),
        isCustom: vehicle.isCustom,
        vehicleCodes: mapToDxgVehicleCodes(vehicle)
    };
};

const mapToDxgPriceCostDetails = (vehicle: IVehicle): IDxgPriceCostDetails => {
    return {
        originalMsrp: mapToDxgMsrp(vehicle),
        invoiceAmount: vehicle.invoiceAmount,
        retailPrice: vehicle.listedPrice,
        retailPriceName: 'Selling Price',
        salesCost: vehicle.cost
    };
};

const mapToDxgVehicleCodes = (vehicle: IVehicle): undefined | IDxgVehicleCodes[] => {
    const { chromeStyleId } = vehicle;
    if (chromeStyleId) {
        return [
            {
                codeType: 'ChromeStyleId',
                chromeStyleId: chromeStyleId
            }
        ];
    }
    return;
};

const mapToDxgMsrp = (vehicle: IVehicle): number => {
    return vehicle.retailPrice || vehicle.listedPrice || 0;
};

const mapToDxgPowertrain = (vehicle: IVehicle): IDxgPowertrain | undefined => {
    if (!vehicle.engine && !vehicle.transmission) {
        return undefined;
    }

    const powertrain: IDxgPowertrain = {};

    if (vehicle.engine) {
        powertrain.engineDetails = {
            description: vehicle.engine
        };
    }
    if (vehicle.transmission) {
        powertrain.transmissionDetails = {
            description: vehicle.transmission
        };
    }

    return powertrain;
};
