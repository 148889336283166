import { CommonConsumer } from '@makemydeal/dr-platform-types';

export const validateConsumer = (consumer?: Partial<CommonConsumer>): boolean => {
    return Boolean(consumer && consumer.firstName && consumer.lastName && consumer.primaryEmail && consumer.postalCode);
};

const consumerFieldMap = {
    firstName: 'first name',
    lastName: 'last name',
    primaryEmail: 'email address',
    postalCode: 'postal code'
} as const;

// To satisfy TS and avoid type casting later
const objectKeys = <T extends object>(obj: T) => {
    return Object.keys(obj) as Array<keyof T>;
};

export const getConsumerMissingFields = (consumer: Partial<CommonConsumer> = {}): string | undefined => {
    const missingFields = objectKeys(consumerFieldMap)
        .filter((key) => {
            return Boolean(!consumer[key]);
        })
        .map((key) => consumerFieldMap[key]);

    if (missingFields.length) {
        return missingFields.join(', ');
    }
    return;
};
