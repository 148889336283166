import { FC, Suspense, useState } from 'react';
import { Card } from '@interstate/components/Card';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { CommonConsumer } from '@makemydeal/dr-platform-types';

import { OpenDealCardHeader } from '../openDealCardHeader/OpenDealCardHeader';
import { FindOrCreateModule } from './FindOrCreateModule';
import { CustomerCardContent, StyledCustomerCardFindOrCreateContent } from './CustomerCard.style';
import { useNewDealContext } from '../NewDealModuleContext';
import { Alert } from '@interstate/components/Alert';
import { IOpenDealConsumer } from '../../types';
import { getConsumerMissingFields, validateConsumer } from '../../validators/commonConsumer';

const enum environments {
    nonprod = 'nonprod',
    prod = 'prod'
}

const nonprodEnvs = ['localhost', 'np.', 'np-', 'dev', 'dev2', 'qa'];

const CONSUMER_REQUIRED_FIELDS = ['firstName', 'lastName', 'email', 'postalCode'];
export const deriveEnvironmentName = () => {
    const url = window.location.href;
    return nonprodEnvs.some((x) => url.includes(x)) ? environments.nonprod : environments.prod;
};

export type CustomerCardProps = {
    consumer?: IOpenDealConsumer;
    onCustomerFindOrCreate: (consumer: CommonConsumer) => void;
    commonOrgId: string;
    isNewDeal: boolean;
};

const isConsumerValid = (canEdit: boolean, consumer?: IOpenDealConsumer) => {
    return Boolean(
        canEdit ? consumer && consumer.commonConsumerId && validateConsumer(consumer.commonConsumerData) : consumer?.fullName
    );
};
const isConsumerComplete = (canEdit: boolean, consumer?: IOpenDealConsumer) => {
    return Boolean(canEdit ? consumer?.commonConsumerId : consumer?.fullName);
};

export const CustomerCard: FC<CustomerCardProps> = ({
    consumer,
    onCustomerFindOrCreate,
    commonOrgId,
    isNewDeal
}: CustomerCardProps) => {
    const theme = useInterstateTheme();
    const [editMode, setEditMode] = useState(() => false);
    const { getAuthOverride } = useNewDealContext();
    const fullName = consumer?.fullName;
    const consumerData = consumer?.commonConsumerData;
    const isValid = isConsumerValid(isNewDeal, consumer);
    const isComplete = isConsumerComplete(isNewDeal, consumer);
    const cardHeaderTitle = isValid ? 'Customer' : 'Customer (required)';
    const cardHeaderAction = isNewDeal ? (editMode ? 'Close' : fullName ? 'Edit' : 'Start') : undefined;

    const showInvalidConsumerError = isNewDeal && isComplete && !isValid;

    const onFindOrCreate = (consumer: CommonConsumer) => {
        onCustomerFindOrCreate(consumer);
        setEditMode(!editMode);
    };

    const renderContent = () => {
        if (isNewDeal && editMode) {
            return (
                <StyledCustomerCardFindOrCreateContent>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FindOrCreateModule
                            dealershipId={commonOrgId}
                            environment={deriveEnvironmentName()}
                            header={{ title: 'Add Customer' }}
                            requiredFields={CONSUMER_REQUIRED_FIELDS}
                            viewportMode={'sidebar'}
                            onFindOrCreate={onFindOrCreate}
                            getAuthOverride={getAuthOverride || (() => Promise.resolve(''))}
                        />
                    </Suspense>
                </StyledCustomerCardFindOrCreateContent>
            );
        }
        if (fullName) {
            return (
                <>
                    <CustomerCardContent data-testid="customer-card-name">{fullName}</CustomerCardContent>
                    {showInvalidConsumerError && (
                        <Alert
                            type="error"
                            data-testid="customer-missing-fields-alert"
                            title="Missing customer data"
                            sx={{ marginTop: '1rem' }}
                        >
                            <span data-testid="customer-missing-fields-alert-message">
                                Selected customer is missing the following required data: {getConsumerMissingFields(consumerData)}
                            </span>
                        </Alert>
                    )}
                </>
            );
        }
        return null;
    };

    return (
        <Card
            sx={{
                backgroundColor: isComplete && isValid && !editMode ? theme.tokens.BaseColorGreen50 : theme.tokens.BaseColorGray50
            }}
            data-testid="customer-card"
            header={
                <OpenDealCardHeader
                    data-testid="customer-card-header"
                    title={cardHeaderTitle}
                    showIcon
                    isComplete={isComplete && isValid}
                    action={cardHeaderAction}
                    onActionClick={() => setEditMode(!editMode)}
                    tooltip="Searching for a phone number requires only numerical values."
                    isNewDeal={isNewDeal}
                />
            }
            content={renderContent()}
        />
    );
};
