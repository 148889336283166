// externals
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Button } from '@interstate/components/Button';

// components
import { OpenDealDialog } from './OpenDealDialog';

// selectors
import { selectors, openDeal, createDeal } from '../../../store';
import { CreateNewDealDialog } from './CreateNewDealDialog';
import { validateConsumer } from '../../../validators/commonConsumer';

export const OpenDeal: FC = () => {
    const dispatch = useDispatch();
    const dealership = useSelector(selectors.getDealership);
    const vehicle = useSelector(selectors.getVehicle);
    const consumer = useSelector(selectors.getConsumer);
    const isConsumerValid = validateConsumer(consumer?.commonConsumerData);
    const isNewDeal = useSelector(selectors.isNewDeal);

    const isEnabledNewDeal = !!consumer && isConsumerValid && !!dealership && vehicle;
    const isEnabledOpenDeal = !!dealership && !!vehicle;

    const isButtonEnabled = isNewDeal ? isEnabledNewDeal : isEnabledOpenDeal;
    const buttonText = isNewDeal ? 'Save Deal' : 'Open';
    const storeFunctionToDispatch = isNewDeal ? createDeal : openDeal;

    const handleOpenDeal = () => {
        dispatch(storeFunctionToDispatch());
    };

    return (
        <>
            <Button data-testid="footer-open-deal-button" disabled={!isButtonEnabled} onClick={handleOpenDeal}>
                {buttonText}
            </Button>

            {isNewDeal ? <CreateNewDealDialog /> : <OpenDealDialog />}
        </>
    );
};
