import { ChangeVehicleInterstate } from '@makemydeal/dr-activities-change-vehicle';
import { IDxgVehicle, IDxgVehicleChromeStyleId, IDxgVehicleCodes } from '@makemydeal/dr-shared-types';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import { OpenDealCardHeader } from '../openDealCardHeader/OpenDealCardHeader';
import { SelectedVehicleInfo } from './SelectedVehicleInfo';
import { ChangeVehicleTitle, StyledVehicleSelect } from './VehicleSelect.style';
import { OpenDealCardInfo } from '../Activity.style';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';

export type VehicleSelectProps = {
    selectedVehicle: IDxgVehicle | undefined;
    editMode: boolean;
    onEditModeChange: () => void;
    coOrgId?: string;
    isNewDeal?: boolean;
};

const isChromeStyleIdCode = (code: IDxgVehicleCodes): code is IDxgVehicleChromeStyleId => {
    return code.codeType === 'ChromeStyleId';
};

const isVehicleValid = (vehicle?: IDxgVehicle): vehicle is IDxgVehicle => {
    const vin = vehicle?.vin;
    const chromeStyleId = vehicle?.vehicleCodes?.find(isChromeStyleIdCode)?.chromeStyleId;

    return Boolean(vin || chromeStyleId);
};

export const VehicleSelect = ({ selectedVehicle, editMode, onEditModeChange, coOrgId, isNewDeal }: VehicleSelectProps) => {
    const theme = useInterstateTheme();
    const isBuildVehicleOnCreateNewDealEnabled = useSelector(featureToggleSelectors.isBuildVehicleOnCreateNewDealEnabled);
    const hasVehicleSelected = isVehicleValid(selectedVehicle);
    const actionText = hasVehicleSelected ? 'Change Vehicle' : 'Add Vehicle';

    // if the toggle is enabled, we want to send FALSE to "isNewDeal" prop so build vehicle will show.
    const isNewDealVehicleChange = isNewDeal ? !isBuildVehicleOnCreateNewDealEnabled : false;

    return (
        <StyledVehicleSelect
            sx={{ backgroundColor: editMode || !hasVehicleSelected ? theme.tokens.BaseColorGray50 : theme.tokens.BaseColorGreen50 }}
            data-testid="vehicle-select"
            header={
                <OpenDealCardHeader
                    title={hasVehicleSelected ? 'Vehicle' : 'Vehicle (required)'}
                    showIcon
                    isComplete={hasVehicleSelected}
                    action={editMode ? 'Close' : hasVehicleSelected ? 'Edit' : 'Start'}
                    onActionClick={onEditModeChange}
                />
            }
            content={
                <>
                    {hasVehicleSelected && (
                        <OpenDealCardInfo>
                            <SelectedVehicleInfo selectedVehicle={selectedVehicle} />
                        </OpenDealCardInfo>
                    )}
                    {editMode && (
                        <>
                            <ChangeVehicleTitle data-testid="change-vehicle-action-title">{actionText}</ChangeVehicleTitle>
                            <ChangeVehicleInterstate
                                applyMessage={actionText}
                                enableConfirmModal={false}
                                coOrgId={coOrgId}
                                isNewDeal={isNewDealVehicleChange}
                            />
                        </>
                    )}
                </>
            }
        />
    );
};
