// types
import { AdditionalSelectors } from '@makemydeal/dr-activities-change-vehicle';
import { getOpenDealSelectors } from '../open-deal-dashboard/config';
import { getServicesBff } from '../../selectors/openDealAppSelectors';

export const additionalSelectors: AdditionalSelectors = {
    getCommonOrgId: (state) => getOpenDealSelectors()?.getCommonOrgId(state) || '',
    getServicesBff,
    getVehicleVin: (state) => getOpenDealSelectors()?.getVehicle(state)?.vin,
    getVehicleStockNumber: (state) => getOpenDealSelectors()?.getVehicle(state)?.stockNumber
};
