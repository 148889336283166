import { CommonConsumer } from '@makemydeal/dr-platform-types';
import { IOpenDealConsumer } from '../types';

export const mapToOpenDealConsumer = (commonConsumer: CommonConsumer): IOpenDealConsumer => {
    return {
        fullName: getConsumerFullName(commonConsumer),
        commonConsumerId: commonConsumer.consumerId,
        commonConsumerData: commonConsumer
    };
};

const getConsumerFullName = ({ firstName, lastName, middleName, suffix }: CommonConsumer) => {
    const nameParts = [firstName, middleName, lastName];

    if (suffix) {
        nameParts.push(`${suffix}.`);
    }

    return nameParts.filter(Boolean).join(' ');
};
